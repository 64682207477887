import React from 'react';
import Container from '../container/container.component';
import { BtnPrimary } from '~components/buttons/buttons.component';

import styles from './cta-featured.module.scss';

const CtaFeatured = props => {
  const {
    title,
    body,
    ctaText,
    ctaUrl,
  } = props;

  return (
    <div className={styles.ctaFeatured}>
      <Container>
        <h2 className={styles.title}>{title}</h2>
        <div className={styles.body}>{body.body}</div>
        <BtnPrimary className={styles.button} path={ctaUrl}>
          {ctaText}
        </BtnPrimary>
      </Container>
    </div>
  );
};

export default CtaFeatured;
