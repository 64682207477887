import React from 'react';
import RenderMarkdown from '~components/RenderMarkdown';
import Container from '~components/container/container.component';

import styles from './hero.module.scss';

const Hero = props => {
  const {
    heroHeadline,
    bynderBackgroundImage,
  } = props.data;

  let imageObj;
  if (bynderBackgroundImage && bynderBackgroundImage.length) {
    imageObj = {
      file: {url: bynderBackgroundImage[0].original},
      // description: bynderBackgroundImage[0].description ? bynderBackgroundImage[0].description : bynderBackgroundImage[0].name,
      description: bynderBackgroundImage[0].name,
    };
  }

  const renderImage = () => {
    if (!imageObj) {
      return null;
    }
    return (
      <div className={styles.imageWrapper}>
        <img
          src={imageObj.file.url}
          alt={imageObj.description}
        />
      </div>
    );
  }

  const renderHeadline = () => {
    if (!heroHeadline) {
      return null;
    }

    return (
      <h1 className={styles.headline}>
        <RenderMarkdown text={heroHeadline.heroHeadline} paragraph="span" />
      </h1>
    );
  }

  return (
    <section>
      <Container>
        {renderImage()}
        {renderHeadline()}
      </Container>
    </section>
  );
}

export default Hero;
