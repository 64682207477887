import React from 'react';
import { Link } from 'gatsby';

const LinkCustom = ({ path, children }) => {
  if (path && path.startsWith('http')) {
    return (
      <a
        href={path}
        title={children}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        {children}
      </a>
    );
  }
  else {
    return (
      <Link to={`/${path}/`}>
        {children}
      </Link>
    );
  }
};

export default LinkCustom;
