import React from 'react';
import Img from 'gatsby-image';
import LinkCustom from '~components/link-custom/link-custom.component';
import { BtnPrimary } from '~components/buttons/buttons.component';
import RenderMarkdown from '~components/RenderMarkdown';

import styles from './featured-card.module.scss';

export default ({ image, title, body, link, linkText, bynderImage, secured = false }) => {
  const renderImg = () => {
    if (!image && !bynderImage) {
      return null;
    }

    if (bynderImage && bynderImage.length) {
      const {
        description,
        name,
        src,
      } = bynderImage[0];
      return (
        <div className={styles.imageWrapper}>
          <img src={src} alt={description ? description : name} />
        </div>
      );
    }

    if (secured) {
      const {
        description,
        file: { url },
      } = image.fields;

      return (
        <img
          alt={description}
          src={url}
        />
      );
    }

    const {
      description,
      fluid,
    } = image;

    return <Img alt={description} fluid={fluid} />;
  }

  const renderBody = () => {
    if (!body) {
      return null;
    }

    const text = secured ? body : body.body;

    return (
      <div className={styles.featuredCardBody}>
        <RenderMarkdown text={text} />
      </div>
    );
  }

  const renderTitle = () => {
    if (!title) {
      return null;
    }

    if (link) {
      return (
        <h2 className={styles.featuredCardTitle}>
          {<LinkCustom path={link}>{title}</LinkCustom>}
        </h2>
      );
    }
    else {
      return <h2 className={styles.featuredCardTitle}>{title}</h2>;
    };
  }

  return (
    <div className={styles.featuredCard}>
      {renderImg()}
      {renderTitle()}
      {renderBody()}
      {link &&
        <div className={styles.cta}>
          <BtnPrimary path={link}>{linkText}</BtnPrimary>
        </div>
      }
    </div>
  );
}
